
// ///<reference path="../../td/jquery.d.ts"/>

class Dependency
{
	private callback:any = null;
	private numLoaded:number = 0;
	private total:number = 0;
	private path:string = "";
	private files:string[] = [];

	constructor(files:string[], callback?, path?)
	{
		//--- store params ---//
		this.files = files;
		if (callback!=null) this.callback = callback;
		if (path!=null) this.path = path;

		//--- count script files ---//
		for(var i:number=0; i<this.files.length;++i)
		{
			var ext = files[i].substring(files[i].lastIndexOf("."));
			if (ext==".js") this.total++;
		}

		//--- load ---//
		this.load()
	}

	load():void
	{
		for(var i:number=0; i<this.files.length;++i)
		{
			var __this = this;
			var fullname = this.path+this.files[i];
			var ext = fullname.substring(fullname.lastIndexOf("."));
			if (ext==".js")
			{
				//$.getScript(fullname, function()
				$.ajax({
					url: fullname,
					dataType: 'script',
					cache: true,
					success: function()
					{
						if (++__this.numLoaded==__this.total && __this.callback!=null) __this.callback();
					}
				});
			}
			else if (ext==".css")
			{
				//console.log('loading css:'+fullname);
				$('<link/>', { rel: 'stylesheet', type: 'text/css', href: fullname }).appendTo('head');
			}
		}
	}
}
